@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@font-face {
  font-family: CenturyGothic;
  src: url(/static/media/GOTHIC.ab135eb4.woff);
}

@font-face {
  font-family: CenturyGothic-Bold;
  src: url(/static/media/GOTHICB.a3fde714.woff);
}

@font-face {
  font-family: CenturyGothic-Bold-Italic;
  src: url(/static/media/GOTHICBI.f2dc73c0.woff);
}

@font-face {
  font-family: CenturyGothic-Italic;
  src: url(/static/media/GOTHICI.bc9aa56b.woff);
}

:root {
  --color-main: #FBCE07;
  --color-darkMain: #d1c300;
  --color-tyc: #F9CC07;
  --color-textDark: #DD1D21;
  --color-tycText: #595959;
  --color-amarillito: #F9CC07;
  --color-hellDarkBlue: #003C88;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #222;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}


body #root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #5554;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background: #FBCE07;
  background: var(--color-main);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #d1c300;
  background: var(--color-darkMain);
}

.swal2-popup {
  border-radius: 20px !important;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-title br {
  font-size: 0 !important;
}

.swal2-image {
  margin: 3vw auto 1vw auto !important;
}

.closePopUp {
  position: absolute;
  top: 0%;
}

button.swal2-close {
  color: #555;
}

.preloader {
  width: 40px;
  height: 40px;

  border: 5px solid transparent;
  border-top: 5px solid #003C88;
  border-top: 5px solid var(--color-hellDarkBlue);
  border-left: 5px solid #003C88;
  border-left: 5px solid var(--color-hellDarkBlue);
  border-bottom: 5px solid #003C88;
  border-bottom: 5px solid var(--color-hellDarkBlue);
  border-radius: 50%;

  margin: 10px auto;

  -webkit-animation-name: girar;

          animation-name: girar;
  -webkit-animation-duration: .9s;
          animation-duration: .9s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.dark {
  width: 20px;
  height: 20px;

  border: 3px solid transparent;
  border-top: 3px solid #595959;
  border-top: 3px solid var(--color-tycText);
  border-left: 3px solid #595959;
  border-left: 3px solid var(--color-tycText);
  border-bottom: 3px solid #595959;
  border-bottom: 3px solid var(--color-tycText);
  border-radius: 50%;

  margin: 0px auto;

  -webkit-animation-name: girar;

          animation-name: girar;
  -webkit-animation-duration: .9s;
          animation-duration: .9s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes girar {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes girar {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

